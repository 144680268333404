import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CookieBanner from './src/components/cookie-banner';
import './src/styles/tailwind.css';

export const wrapRootElement = ({ element }) => {
  console.log('element', element)
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      {element}
      <CookieBanner />
    </GoogleReCaptchaProvider>
  )
}
