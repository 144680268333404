import React, { useEffect, useState } from 'react'
import { GlobeIcon, XIcon } from '@heroicons/react/outline'
import useCookie from '../hooks/useCookie'

export default function CookieBanner() {
  const [acked, setAcked, removeAcked] = useCookie('cookieacked')
  const [show, setShow] = useState(!acked)

  const ack = () => {
    setShow(false)
    setAcked(true)
  }

  if (!show) {
    return (
      <></>
    )
  }

  return (
    <div className="z-50 fixed bottom-0 inset-x-0 pb-2 md:pb-5">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-gray-800 shadow-lg sm:p-3">
          <div className="flex items-center justify-end md:justify-between flex-wrap">
            <div className="w-full md:w-0 md:flex-1 flex items-center">
              <span className="hidden md:flex p-2 rounded-lg bg-teal-400">
                <GlobeIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="md:ml-3 font-medium text-white">
                <span>This site makes use of cookies to provide you with a better experience on our site.</span>
              </p>
            </div>
            <div className="mr-1 mt-2 flex-shrink-0 md:mt-0 md:ml-3">
              <a
                href="privacy-policy"
                className="flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white text-white hover:text-teal-400 text-xs"
              >
                More Information
              </a>
            </div>
            <div className="mr-1 mt-2 flex-shrink-0 md:mt-0">
              <button
                type="button"
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium rounded-md text-white bg-teal-400 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
                onClick={ack}
              >
                Okay
              </button>
            </div>
            <div className="mt-2 flex-shrink-0 md:mt-0">
              <button
                type="button"
                className="flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white text-white hover:text-teal-400 text-xs"
                onClick={() => setShow(false)}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
